import React, { useState } from "react";
import TextPopup from "../TextPopup/TextPopup";
import { MdOutlineRemoveRedEye } from "react-icons/md";


const Description = ({ text, limit }) => {
  const [showPopup, setShowPopup] = useState(false);

  const truncatedText = text ? text.slice(0, limit) : "";

  const handleShowMore = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div>
      {truncatedText}
      {text && text.length > limit && (
        <button onClick={handleShowMore} className="ml-1  text-[16px] px-1 py-0.5 border rounded-xl bg-light-orange text-white">
        <MdOutlineRemoveRedEye     />  
       </button>
      )}

      {showPopup && <TextPopup text={text} onClose={handleClosePopup} />}
    </div>
  );
};

export default Description;
