import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";

export const getAllIngredients = createAsyncThunk(
  "api/getAllIngredients",
  async () => {
    try {
      const response = await axiosInstance.get(
        "/ingredients/getAllIngredients"
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getSubIngredientById = createAsyncThunk(
  "api/getSubIngredientById",
  async (ingredientId) => {
    try {
      const response = await axiosInstance.get(
        `/subIngredient/getSubIngredientById/${ingredientId}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const searchIngredients = createAsyncThunk(
  "api/searchIngredients",
  async ({ type, query }) => {
    try {
      const response = await axiosInstance.get(
        `/ingredients/search?type=${type}&query=${query}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

const apiAllIngredientSlice = createSlice({
  name: "apiGetAllIngredients",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getAllIngredients.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllIngredients.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })

      .addCase(getAllIngredients.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getSubIngredientById.pending, (state) => {
        state.loading = true;
        state.user = null;
        state.error = null;
      })
      .addCase(getSubIngredientById.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(getSubIngredientById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(searchIngredients.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchIngredients.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(searchIngredients.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default apiAllIngredientSlice.reducer;
