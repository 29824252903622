import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Clients from "../../components/Clients/Clients";

import Search from "../../components/Search/Search";
import {
  getAllClients,
  deleteClient,
} from "../../store/slices/apiClientSlice";
import Pagination from "../../components/Pagination/Pagination";

const ClientList = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const clients = useSelector((state) => state.apiClient?.data) || [];

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  const handleGoBack = () => {
    window.history.back();
  };

  useEffect(() => {
    dispatch(getAllClients());
  }, [dispatch]);

    const filteredClientData = clients.filter((client) =>
      client?.name?.toLowerCase()?.includes(searchTerm.toLowerCase())
    );

  useEffect(() => {
    if (!clients.length) {
      dispatch(getAllClients());
    }
  }, [dispatch, clients.length]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentClients = filteredClientData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDeleteClient = (clientId) => {
    dispatch(deleteClient(clientId))
      .unwrap()
      .then(() => {
        dispatch(getAllClients());
      })
      .catch((error) => {
        console.error("Failed to delete client: " + error.message);
      });
  };

  return (
    <div className="home-container">
      <div className="bg-light-pink h-89">
        <div className="container flex items-center cursor-pointer cursor-pointer">
          <button className="flex items-center" onClick={handleGoBack}>
            <img
              src="images/goback-icon.svg"
              alt="Go back"
              className="mr-2.5 w-7 h-7 lg:w-7 xs:w-5 cursor-pointer"
            />
            <p className="text-xl lg:text-xl font-normal xs:text-base cursor-pointer">Go back</p>
          </button>

          <div className="flex-1 justify-center text-center">
            <h1 className="text-xl lg:text-xl font-normal xs:text-lg">
              Client List
            </h1>
          </div>
        </div>
      </div>
      <div className="flex-1 justify-end text-left">
        <Search onSearch={handleSearch} />
      </div>
      <div>
        {currentClients.map((client) => (
          <Clients
            key={client?.id}
            id={client?.id}
            name={client?.name}
            number={client?.phone_number}
            commentClient={client?.comment}
            stars={client?.stars}
            onDelete={() => handleDeleteClient(client?.id)}
          />
        ))}
      </div>
      <Pagination
        activePage={currentPage}
        onPageChange={handlePageChange}
        itemsPerPage={itemsPerPage}
        totalItems={filteredClientData.length}
      />
    </div>
  );
};

export default ClientList;
