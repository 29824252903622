import React, { useEffect, useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit2 } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataRecipes } from "../../store/slices/apiRecipes";
import { deleteDataRecipes } from "../../store/slices/apiRecipes";
import { Link } from "react-router-dom";
import DeleteRecipe from "../DeleteRecipe/DeleteRecipe";
import Description from "../Description/Description";

const CardRows = () => {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteRecipeId, setDeleteRecipeId] = useState(null);

  const recipes = useSelector((state) => state.apiRecipes?.data?.recipes || []);

  useEffect(() => {
    dispatch(fetchDataRecipes());
  }, [dispatch]);

  const handleDeleteClick = (itemId) => {
    setShowDeleteModal(true);
    setDeleteRecipeId(itemId);

    dispatch(deleteDataRecipes(itemId))
      .unwrap()
      .then(() => {
        console.log("Deleted successfully");
        window.location.reload();
        return;
      })
      .catch((error) => {
        console.error("Delete failed:", error);
      });
  };

  return (
    <div className="container flex justify-center">
      <div className="container">
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-6">
            <div className="inline-block min-w-full py-1  sm:px-6 lg:px-8  ">
              <div className="overflow-hidden ">
                <table className="min-w-full border text-center text-sm  ">
                  <thead className="border-b  text-light-orange bg-white-light">
                    <tr>
                      <th
                        scope="col"
                        className="border-r px-6 py-3 font-normal w-[50px] "
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        className="border-r px-6 py-3 font-normal w-[150px]"
                      >
                        Title
                      </th>
                      <th
                        scope="col"
                        className="border-r px-6 py-3 font-normal w-[150px]"
                      >
                        Category
                      </th>
                      <th
                        scope="col"
                        className="border-r px-6 py-3 font-normal w-[150px] "
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        className="border-r px-6 py-3 font-normal w-[150px] "
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        className="border-r px-6 py-3 font-normal w-[150px]"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(recipes) &&
                      recipes.map((recipe) => (
                        <tr key={recipe.id} className="border-b font-light">
                          <td className="whitespace-nowrap border-r px-6 py-3">
                            {recipe.id}
                          </td>
                          <td className="whitespace-nowrap border-r px-6 py-3">
                            {recipe.title}
                          </td>
                          <td className="whitespace-nowrap border-r px-6 py-3">
                            {recipe.categories}
                          </td>

                          <td className="whitespace-nowrap border-r px-6 py-3">
                            <Description
                              text={recipe.preparation || "--"}
                              limit={11}
                            />
                          </td>

                          <td
                            className={`whitespace-nowrap border-r px-6 py-3 ${
                              recipe.visibility === 1 ? "active" : "inactive"
                            }`}
                          >
                            {recipe.visibility === 1 ? "Active" : "Pasive"}
                          </td>

                          <td className="whitespace-nowrap border-r px-6 py-3 text-gray-400 flex justify-center">
                            <Link className="mr-2 " to={`/edit/${recipe.id}`}>
                              <FiEdit2
                                size="16px "
                                className="cursor-pointer"
                              />
                            </Link>
                            <button
                              onClick={() => {
                                setShowDeleteModal(true);
                                setDeleteRecipeId(recipe.id);
                              }}
                            >
                              <RiDeleteBin6Line
                                size="16px"
                                className="cursor-pointer"
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showDeleteModal && (
        <DeleteRecipe
          onClose={() => setShowDeleteModal(false)}
          itemId={deleteRecipeId}
          handleDeleteClick={handleDeleteClick}
        />
      )}
    </div>
  );
};

export default CardRows;
