import React, { useState } from "react";
import { addStar, getReservation } from "../../store/slices/getReservation";
import { getAllClients } from "../../store/slices/apiClientSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { RiStarFill, RiStarSLine } from "react-icons/ri";
import { RiDeleteBin4Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import { FaRegCommentDots } from "react-icons/fa";
import { Link } from "react-router-dom";
import { deleteClient, addComment } from "../../store/slices/apiClientSlice";
import PropTypes from "prop-types";
import { useEffect } from "react";

const Clients = ({ id, name, number, stars, commentClient }) => {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [comment, setComment] = useState(commentClient || "");

  const handleBiblockClick = (reservationId, clickedStarIndex) => {
    dispatch(addStar({ reservationId, stars: clickedStarIndex + 1 }))
      .unwrap()
      .then(() => {
        toast.success("Stars updated successfully");
        dispatch(getAllClients());
      })
      .catch((error) => {
        toast.error("Failed to add stars: " + error.message);
      });
  };

  const handleDelete = () => {
    dispatch(deleteClient(id));
    setShowDeleteModal(false);
    toast.success("Client Deleted Successfully");
  };

  const handleComment = () => {
    dispatch(addComment({ id, comment }));
    setShowCommentModal(false);
    toast.success("Comment added successfully");
  };

  return (
    <>
      <div className="container">
        <div className="flex justify-between border rounded-lg mb-4 px-5 py-3 relative mt-4">
          <div className="font-mediu text-gray-900 whitespace-nowrap lg:whitespace-nowrap w-14 xs:whitespace-normal">
            {name}
          </div>
          <div>{number}</div>
          <div className="flex items-center">
            <Link
              to={`/edit-clients/${id}`}
              className=" rounded-full bg-light-pink p-2"
            >
              <FiEdit />
            </Link>
            <button
              className="rounded-full bg-light-pink p-2 cursor-pointer mr-2"
              onClick={() => setShowDeleteModal(true)}
              aria-label="Delete User"
            >
              <RiDeleteBin4Line />
            </button>

            <button
              className="rounded-full bg-light-pink p-2 cursor-pointer mr-2"
              onClick={() => setShowCommentModal(true)}
              aria-label="Delete User"
            >
              { commentClient ?  <FaRegCommentDots className="text-yellow-500" /> : <FaRegCommentDots />  }
             
            </button>

            <div className="flex gap-1">
              {[0, 1, 2, 3, 4].map((index) => (
                <div
                  key={index}
                  className=" "
                  onClick={() => handleBiblockClick(id, index)}
                  onKeyDown={() => {}}
                  role="button"
                  tabIndex={0}
                >
                  {index < stars ? (
                    <RiStarFill className="text-yellow-500" />
                  ) : (
                    <RiStarSLine className="text-black" />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {showDeleteModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-96 text-center">
            <h3 className="text-xl font-bold mb-4">Confirm Deletion</h3>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete this user?
            </p>
            <div className="flex justify-center">
              <button
                className="py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 mr-2"
                onClick={() => setShowDeleteModal(false)}
              >
                No, cancel
              </button>
              <button
                className="py-2 px-4 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300"
                onClick={handleDelete}
              >
                Yes, I'm sure
              </button>
            </div>
          </div>
        </div>
      )}

      {showCommentModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-96 text-center">
            <h3 className="text-xl font-bold mb-4">Add Notes</h3>
            <textarea
              className="text-gray-600 mb-6 border w-full min-h-[120px] max-h-[120px] outline-none p-2"
              placeholder="Add note here"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <div className="flex justify-center">
              <button
                className="py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 mr-2"
                onClick={() => setShowCommentModal(false)}
              >
                No, cancel
              </button>
              <button
                className="py-2 px-4 text-sm font-medium text-white bg-green-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300"
                onClick={handleComment}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Clients.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  stars: PropTypes.number.isRequired,
};

export default Clients;
