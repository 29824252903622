import React, { useState, useEffect, useRef } from "react";
import { GoFilter } from "react-icons/go";
import { GoPlus } from "react-icons/go";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setFilteredRecipe } from "../../store/slices/filterSlice";
import { PiColumns, PiRows } from "react-icons/pi";

const Filter = ({ onHorizontalClick, onVerticalClick, recipeCount }) => {
  const dispatch = useDispatch();
  const [filterOptionsVisible, setFilterOptionsVisible] = useState(false);
  const [addMoreOptionsVisible, setAddMoreOptionsVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Active");
  const filterRef = useRef(null);
  const addMoreRef = useRef(null);

  const toggleFilterOptions = (option) => {
    setFilterOptionsVisible(!filterOptionsVisible);
    setAddMoreOptionsVisible(false);

    if (option === "Active Recipes") {
      dispatch(setFilteredRecipe(1));
      setSelectedFilter("Active");
    } else if (option === "Passive Recipes") {
      dispatch(setFilteredRecipe(0));
      setSelectedFilter("Passive");
    }
  };

  const filterOptions = ["Active Recipes", "Passive Recipes"];

  const toggleAddMoreOptions = () => {
    setAddMoreOptionsVisible(!addMoreOptionsVisible);
    setFilterOptionsVisible(false);
  };

  const addMoreOptions = [
    {
      text: "Add Recipe",
      link: "/create",
    },
    {
      text: "Add Sub Ingridients",
      link: "/create-subingredient",
    },
  ];

  useEffect(() => {
    const handleGlobalClick = (event) => {
      if (
        filterRef.current &&
        !filterRef.current.contains(event.target) &&
        addMoreRef.current &&
        !addMoreRef.current.contains(event.target)
      ) {
        setFilterOptionsVisible(false);
        setAddMoreOptionsVisible(false);
      }
    };
    const handleAddMoreClick = (event) => {
      if (addMoreRef.current && !addMoreRef.current.contains(event.target)) {
        setAddMoreOptionsVisible(false);
      }
    };

    document.addEventListener("click", handleGlobalClick);
    document.addEventListener("click", handleAddMoreClick);

    return () => {
      document.removeEventListener("click", handleGlobalClick);
      document.removeEventListener("click", handleAddMoreClick);
    };
  }, []);

  const handleOptionKeyDown = (event, option) => {
    if (event.key === "Enter") {
      toggleFilterOptions(option);
    }
  };

  const handleHorizontalClick = () => {
    setFilterOptionsVisible(false);
    setAddMoreOptionsVisible(false);
    onHorizontalClick();
  };

  const handleVerticalClick = () => {
    setFilterOptionsVisible(false);
    setAddMoreOptionsVisible(false);
    onVerticalClick();
  };

  return (
    <div className="flex justify-center">
      <div className="flex justify-between my-4 w-1240">
        <div className="flex">
          <p className="font-normal text-base text-gray-color mt-2 leading-6 lg:text-base xs:text-sm xs:ml-4">
            {selectedFilter}
          </p>
        </div>
        <div className="flex justify-end items-center w-full ">
          <div className="flex justify-center items-center">
            <Link to="/ingredients" className="cursor-pointer">
              <div className="text-sm  text-[#C7C4C2] bg-[#F5F1EE] rounded-md py-2 px-5 hover:bg-light-orange hover:text-white transition duration-300 ease-in-out">
                Ingredients
              </div>
            </Link>
          </div>
          <div className="flex justify-center items-center">
            <Link to="/sub-ingredients" className="cursor-pointer">
              <div className="text-sm ml-6   text-[#C7C4C2] bg-[#F5F1EE] rounded-md p-2 hover:bg-light-orange hover:text-white transition duration-300 ease-in-out">
                Sub Ingredients
              </div>
            </Link>
          </div>
          <div
            className={`flex bg-white ml-4  ${
              filterOptionsVisible ? "justify-center" : " "
            }`}
            onClick={() => toggleFilterOptions()}
            ref={filterRef}
          >
            <img
              src="images/Cart.svg"
              className="cursor-pointer transition duration-300 ease-in-out hover:scale-75 w-[45px] "
              alt="Filter"
            />
            {filterOptionsVisible && (
              <div className="absolute bg-white border border-gray-300 mt-14 rounded-md p-2 w-172 lg:w-172 z-10 ">
                {filterOptions.map((option) => (
                  <div
                    key={option}
                    className="flex justify-center hover:bg-light-orange hover:rounded-md hover:text-white hover:w-full text-center text-gray-text text-xs w-full py-2 cursor-pointer z-10"
                    onClick={() => toggleFilterOptions(option)}
                    onKeyDown={(event) => handleOptionKeyDown(event, option)}
                    tabIndex={0}
                    role="button"
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="flex justify-center items-center">
            <div
              className={`flex mr-2 cursor-pointer${
                addMoreOptionsVisible ? " justify-center" : ""
              }`}
              onClick={toggleAddMoreOptions}
              ref={addMoreRef}
            >
              <img
                src="images/Cart-add.svg"
                className="cursor-pointer transition duration-300 ease-in-out hover:scale-75 w-[45px]"
                alt="Add More"
              />
              {addMoreOptionsVisible && (
                <div className="absolute bg-white border border-gray-300 mt-14 rounded-md p-2 w-172 lg:w-172 z-10 ">
                  {addMoreOptions.map((option) =>
                    option.link ? (
                      <Link to={option.link} key={option.text}>
                        <div className="flex justify-center hover:bg-light-orange hover:rounded-md hover:text-white hover:w-full text-center text-gray-text text-xs w-full py-2 cursor-pointer z-10">
                          {option.icon} {option.text}
                        </div>
                      </Link>
                    ) : (
                      <div
                        key={option.text}
                        className="flex justify-center hover:bg-light-orange hover:rounded-md hover:text-white hover:w-full text-center text-gray-text text-xs w-full py-2 cursor-pointer"
                      >
                        {option.icon} {option.text}
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="flex">
            <div className="flex">
              <div
                className="mr-2 cursor-pointer relative max-w-xs overflow-hidden bg-cover bg-no-repeat  bg-[#F5F1EE] p-2 rounded-full transition duration-300 ease-in-out hover:scale-75  "
                onClick={handleHorizontalClick}
              >
                <PiRows className=" text-[#C7C4C2] " />
              </div>
              <div
                className="mr-2 cursor-pointer relative max-w-xs overflow-hidden bg-cover bg-no-repeat bg-[#F5F1EE] p-2 rounded-full transition duration-300 ease-in-out hover:scale-75 "
                onClick={handleVerticalClick}
              >
                <PiColumns className="  text-[#C7C4C2] " />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;
