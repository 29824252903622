import React, { useState } from "react";

import SpecialsOfTheDay from "../../components/SpecialsOfTheDay/SpecialsOfTheDay";
import MenuCategories from "../../components/MenuCategories/MenuCategories";
import AllMenus from "../../components/AllMenus/AllMenus";

const Menu = () => {
  const [selectedCategoryData, setSelectedCategoryData] = useState("Show All");

  return (
    <>
      {" "}
      <SpecialsOfTheDay />
      <MenuCategories setSelectedCategoryData={setSelectedCategoryData} />
      <AllMenus selectedCategoryData={selectedCategoryData} />
    </>
  );
};

export default Menu;
