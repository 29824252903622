import { createSlice } from "@reduxjs/toolkit";

const reservationSlice = createSlice({
  name: "reservationFilter",
  initialState: {
    filteredReservation: 1,
  },
  reducers: {
    setFilteredReservation: (state, action) => {
      state.filteredReservation = action.payload;
    },
  },
});

export const { setFilteredReservation } = reservationSlice.actions;
export const selectFilteredReservation = (state) =>
  state.filter.filteredReservation;
export default reservationSlice.reducer;

// import { createSlice } from "@reduxjs/toolkit";

// const reservationSlice = createSlice({
//   name: "reservationFilter",
//   initialState: {
//     tableNumber: null,
//     filteredReservation: 1,
//   },
//   reducers: {
//     setFilteredReservation: (state, action) => {
//       const { tableNumber, filteredReservation } = action.payload;
//       state.tableNumber = tableNumber;
//       state.filteredReservation = filteredReservation;
//     },
//   },
// });

// export const { setFilteredReservation } = reservationSlice.actions;
// export const selectFilteredReservation = (state) => state.filter;
// export default reservationSlice.reducer;
