import React from "react";
import ReactDOM from "react-dom";

const MediaModal = ({ isOpen, onClose, mediaType, mediaUrl }) => {
  if (!isOpen) return null;

  const closeModal = () => {
    onClose();
  };

  return ReactDOM.createPortal(
    <div className="modal-overlay" onClick={closeModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {mediaType === "image" && (
          <img
            src={mediaUrl}
            alt="Full view"
            style={{
              maxWidth: "90vw",
              maxHeight: "90vh",
              width: "auto",
              height: "auto",
            }}
          />
        )}
        {mediaType === "video" && (
          <video
            controls
            className="w-full h-full"
            style={{ objectFit: "contain" }}
          >
            <source src={mediaUrl} type="video/mp4" />
          </video>
        )}
      </div>
    </div>,
    document.body
  );
};

export default MediaModal;
