import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { deleteDataQuantity } from "../../store/slices/apiRecipes";
import {
  updateSemiIngredients,
  deleteSubIngredientPhoto,
} from "../../store/slices/apiSemiIngredient";
import { AiFillLock, AiOutlineDelete } from "react-icons/ai";
import { FiTrash } from "react-icons/fi";
import { MdOutlineAdd } from "react-icons/md";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DropdownIngredients from "../DropdownIngredients/DropdownIngredients";
import { setFilteredRecipe } from "../../store/slices/filterSlice";
import { getAllIngredients, getSubIngredientById } from "../../store/slices/getAllIngredients";
import { RotatingLines } from "react-loader-spinner";

const EditTheSemiIngredient = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const dataSubIngredients = useSelector(
    (state) => state.apiSemiIngredients.data
  );
  const [changeTitle, setChangeTitle] = useState([""]);
  const [changeDescription, setChangeDescription] = useState("");
  const [changeIngredients, setChangeIngredients] = useState("");
  const [selectedIngredient, setSelectedIngredient] = useState("");
  const [photos, setPhotos] = useState([]);
  const [mediaFiles, setMediaFiles] = useState([]);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [loading, setLoading] = useState(false);


  const [recipeIngredients, setRecipeIngredients] = useState([
    {
      id: null,
      quantity: "",
      unit: "",
      ingredients: "",
      ingredient_cost: "",
      total_cost: "",
    },
  ]);
  const navigate = useNavigate();

  const [ingredientsDropdown, setIngredientsDropdown] = useState(
    recipeIngredients?.map(() => ({ isOpen: false, selectedOption: "" }))
  );

  useEffect(() => {
    if (recipeIngredients.length === 0) {
      setIngredientsDropdown([]);
    } else {
      setIngredientsDropdown(new Array(recipeIngredients.length).fill(false));
    }
  }, [recipeIngredients]);
  const [selectedIngredientsForRow, setSelectedIngredientsForRow] = useState(
    []
  );

  useEffect(() => {
    let tempArray = [];
    recipeIngredients.forEach((ingredient) => {
      tempArray.push({
        ...ingredient,
        ingredients: {
          id: ingredient?.id,
          value: ingredient?.name,
        },
      });
    });

    setSelectedIngredientsForRow(tempArray);
  }, [recipeIngredients]);

  useEffect(() => {
    dispatch(getAllIngredients());
  }, []);

  const ingredients = useSelector((state) => state.apiGetAllIngredients.data);

  const baseUrl = "https://backrecrez.bbros.al";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(getSubIngredientById(id));
        const itemToEdit = response.payload;

        if (itemToEdit) {
          setChangeTitle(itemToEdit.ingredients || "");
          setChangeDescription(itemToEdit.description || "");
          setChangeIngredients(itemToEdit.title || "");
          setSelectedIngredient(itemToEdit.title || "");
          setOverallTotal(itemToEdit.title[0]?.cost || 0);
          setTotalQty(itemToEdit ? itemToEdit.total_unit : "");
          setItemToEdit(itemToEdit); 

          const initialIngredients = itemToEdit.title || [];

          setRecipeIngredients(initialIngredients);

          const initialMediaFiles = itemToEdit.videos || [];
          setMediaFiles(initialMediaFiles);

          setIngredientsDropdown(
            new Array(initialIngredients.length).fill(false)
          );

          const isRecipeActive =
            itemToEdit && itemToEdit.isActive !== undefined
              ? itemToEdit.isActive
              : true;
          dispatch(setFilteredRecipe(isRecipeActive ? "1" : "0"));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (!itemToEdit || itemToEdit.id.toString() !== id) {
      fetchData();
    }
  }, [dataSubIngredients, id, itemToEdit]);

  const handleEditItem = async (e) => {
    e.preventDefault();
    setLoading(true);


    try {
      const formData = new FormData();
      formData.append("ingredients", changeTitle);
      formData.append("description", changeDescription);

      formData.append("recipeId", id);

      const ingredientsData = recipeIngredients.map((ingredient, index) => ({
        quantity: ingredient.quantity,
        unit: ingredientUnits[index],
        ingredients:
          ingredient.id ||
          (Array.isArray(itemToEdit.recipeDetails) &&
            itemToEdit.recipeDetails[index]?.id) ||
          null,
        cost: ingredientCosts[index] || ingredient.cost,
        total_cost: calculatedTotal[index] || ingredient.total_cost,
        name: ingredient.name,
      }));

      const ingredientsArray = selectedIngredientsForRow.map(
        ({ ingredients, ...rest }) => rest
      );

      formData.append("title", JSON.stringify(ingredientsData));
      formData.append("cost", overallTotal.toFixed(2));
      formData.append("total_unit", totalQty);

      media.forEach((item) => {
        formData.append("videos", item);
      });

      const response = await dispatch(
        updateSemiIngredients({ id: id, updatedData: formData })
      );

      toast.success("Changes saved successfully!");
      navigate("/sub-ingredients");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to save changes: " + error);
    }finally {
      setLoading(false);
    }
  };

  const handleAddField = () => {
    const newIngredient = {
      id: null,
      quantity: "",
      unit: "",
      ingredients: "",
      ingredient_cost: "",
      total_cost: "",
    };

    newIngredient.id = newIngredient.ingredients;

    newIngredient.ingredients = selectedIngredient?.id || "";

    setIngredientsDropdown((prevDropdowns) => [
      ...prevDropdowns,
      { isOpen: false },
    ]);

    setRecipeIngredients([...recipeIngredients, newIngredient]);
  };

  const handleIngredients = (index, option) => {
    setSelectedIngredient(option);

    const updatedSelectedIngredients = [...selectedIngredientsForRow];
    updatedSelectedIngredients[index] = {
      ...updatedSelectedIngredients[index],
      ingredients: { id: option?.id, value: option?.ingredients },
    };

    setSelectedIngredientsForRow(updatedSelectedIngredients);

    const updatedDropdowns = [...ingredientsDropdown];
    updatedDropdowns[index] = { ...updatedDropdowns[index], isOpen: false };
    setIngredientsDropdown(updatedDropdowns);

    const updatedIngredients = [...recipeIngredients];

    updatedIngredients[index] = {
      ...updatedIngredients[index],
      title: option?.title,
      ingredients: { id: option?.id, value: option?.ingredients },
      id: option?.id,
      name: option?.ingredients,
    };
    const selectedIngredientId = option?.id || null;

    updatedIngredients[index].id = selectedIngredientId;

    setRecipeIngredients(updatedIngredients);
  };

  const handleDeletePhoto = (photoId, index) => {
    dispatch(deleteSubIngredientPhoto({ itemId: photoId, index }))
      .unwrap()
      .then(() => {
        const updatedPhotos = [...photos];
        updatedPhotos.splice(index, 1);
        setPhotos(updatedPhotos);
      })
      .catch((error) => {
        toast.error("Failed to delete photo: " + error.message);
      });
  };

  const handleDeleteQuantity = (quantityId, index) => {
    dispatch(deleteDataQuantity(quantityId))
      .unwrap()
      .then(() => {
        const updatedIngredients = [...recipeIngredients];
        updatedIngredients.splice(index, 1);
        setRecipeIngredients(updatedIngredients);
      })
      .catch((error) => {
        toast.error("Failed to delete quantity: " + error);
      });
  };

  const [ingredientCosts, setIngredientCosts] = useState(
    recipeIngredients.map(() => "")
  );
  const [ingredientUnits, setIngredientUnits] = useState(
    recipeIngredients.map(() => "")
  );
  const [calculatedTotal, setCalculatedTotal] = useState(
    recipeIngredients.map(() => "")
  );

  const [ingredientTotalCosts, setIngredientTotalCosts] = useState(
    recipeIngredients.map(() => 0)
  );

  const [overallTotal, setOverallTotal] = useState(0);

  const handleIngredientCostAndUnit = ({ cost, unit }, index) => {
    const updatedCosts = [...ingredientCosts];
    updatedCosts[index] = parseFloat(cost) || 0;
    setIngredientCosts(updatedCosts);

    const updatedUnits = [...ingredientUnits];
    updatedUnits[index] = unit;
    setIngredientUnits(updatedUnits);

    const quantity = parseFloat(recipeIngredients[index].quantity) || 0;
    const totalCost = (quantity * updatedCosts[index]).toFixed(2);

    const updatedTotalCosts = [...ingredientTotalCosts];
    updatedTotalCosts[index] = parseFloat(totalCost) || 0;
    setIngredientTotalCosts(updatedTotalCosts);

    const newOverallTotal = updatedTotalCosts.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    setOverallTotal(newOverallTotal);
  };

  const handleQuantityChange = (e, index) => {
    const quantity = parseFloat(e.target.value) || 0;
    const cost = parseFloat(ingredientCosts[index]) || 0;

    const total = (quantity * cost).toFixed(2);

    const updatedCalculatedTotal = [...calculatedTotal];
    updatedCalculatedTotal[index] = total;
    setCalculatedTotal(updatedCalculatedTotal);

    const updatedIngredients = [...recipeIngredients];
    updatedIngredients[index] = {
      ...updatedIngredients[index],
      quantity: e.target.value,
    };
    setRecipeIngredients(updatedIngredients);

    const updatedTotalCosts = [...ingredientTotalCosts];
    updatedTotalCosts[index] = parseFloat(total) || 0;
    setIngredientTotalCosts(updatedTotalCosts);

    const newOverallTotal = updatedTotalCosts.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    setOverallTotal(newOverallTotal);
  };

  const [media, setMedia] = useState([]);

  const handleFileUpload = (event) => {
    const files = event.target.files;
    setMedia([...media, ...Array.from(files)]);
  };

  const [totalQty, setTotalQty] = useState("");

  useEffect(() => {
    const newTotalQty = recipeIngredients.reduce((accumulator, ingredient) => {
      const unit = ingredient.unit?.toLowerCase();

      if (unit && ["kg", "gr", "g"].includes(unit)) {
        const quantity = parseFloat(ingredient.quantity);

        if (!isNaN(quantity)) {
          return accumulator + quantity;
        } else {
          console.log(`Skipping invalid quantity for ${unit}`);
        }
      } else {
        console.log(`Skipping ${ingredient.quantity} ${unit}`);
      }

      return accumulator;
    }, 0);

    setTotalQty(newTotalQty);
  }, [recipeIngredients]);

  return (
    <div className="flex justify-center mt-20">
      <div className="items-center justify-center flex mt-20">
        <div className="mx-auto mt-16 max-w-xl sm:mt-20">
          <form onSubmit={handleEditItem} className="flex">
            <div>
              <input
                name="title"
                id="title"
                placeholder="Type Menu or Event Name"
                className="block w-[570px] h-46 rounded-md border-0 px-3.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={changeTitle}
                onChange={(e) => setChangeTitle(e.target.value)}
              />

              <div>
                <div className="grid grid-rows-1 gap-x-2 sm:grid-rows-1">
                  {recipeIngredients.map((ingredient, index) => (
                    <div key={index} className="flex gap-x-2 mt-5">
                      <label className="text-sm font-medium leading-5">
                        <p className="mb-3">Ingredient</p>

                        <DropdownIngredients
                          ingredients={ingredients}
                          selectedOption={
                            selectedIngredientsForRow[index]?.ingredients
                          }
                          isOpen={ingredientsDropdown[index].isOpen || false}
                          onSelect={(option) => {
                            handleIngredients(index, option);
                          }}
                          toggleDropdown={() =>
                            setIngredientsDropdown((prevDropdowns) =>
                              prevDropdowns.map((d, i) =>
                                i === index ? { ...d, isOpen: !d.isOpen } : d
                              )
                            )
                          }
                          setIngredientCostAndUnit={(costUnit) =>
                            handleIngredientCostAndUnit(costUnit, index)
                          }
                        />
                      </label>

                      <label className="text-sm font-medium leading-5">
                        Qty
                        <input
                          name={`qty-${index}`}
                          placeholder="Qty"
                          className="mt-3 block w-[60px] h-12 rounded-md border-0 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={ingredient.quantity}
                          onChange={(e) => handleQuantityChange(e, index)}
                        />
                      </label>

                      <label className="text-sm font-medium leading-5">
                        Unit
                        <input
                          name={`unit-${index}`}
                          placeholder="Unit"
                          className="mt-3 block w-[70px] h-12 rounded-md border-0 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={ingredientUnits[index] || ingredient.unit}
                          onChange={(e) => {
                            const updatedUnits = [...ingredientUnits];
                            updatedUnits[index] = e.target.value;
                            setIngredientUnits(updatedUnits);
                          }}
                          disabled
                        />
                      </label>

                      <label className="text-sm font-medium leading-5">
                        <p className=""> Cost</p>
                        <input
                          name={`cost-${index}`}
                          placeholder="Cost"
                          className="mt-3 block w-[90px] h-12 rounded-md border-0 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={ingredientCosts[index] || ingredient.cost}
                          onChange={(e) => {
                            const updatedCosts = [...ingredientCosts];
                            updatedCosts[index] = e.target.value;
                            setIngredientCosts(updatedCosts);
                          }}
                          disabled
                        />
                      </label>
                      <label className="text-sm font-medium leading-5">
                        <p className=""> Total</p>
                        <input
                          name={`total-cost-${index}`}
                          placeholder="Total "
                          className="mt-3 block w-[90px] h-12 rounded-md border-0 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={
                            calculatedTotal[index] || ingredient.total_cost
                          }
                          readOnly
                          disabled
                        />
                      </label>

                      <div className="flex items-end gap-2">
                        {index === recipeIngredients.length - 1 && (
                          <button
                            type="button"
                            className="ml-2 p-3 bg-light-pink rounded-full w-12 h-12"
                            onClick={handleAddField}
                          >
                            <MdOutlineAdd className="w-6 h-6 text-gray-color" />
                          </button>
                        )}

                        <button
                          type="button"
                          className="p-3 bg-light-pink rounded-full w-12 h-12"
                          onClick={() =>
                            handleDeleteQuantity(ingredient.id, index)
                          }
                        >
                          <FiTrash className="w-5 h-5 text-gray-color" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className=" w-full ">
                <div className="sm:col-span-2 mt-4 w-full flex ml-[170px] ">
                  <label className="text-sm font-medium leading-5">
                    <p>{`Total Qty`} </p>
                    <input
                      placeholder="Total "
                      className="mt-3 block w-[60px] h-12 rounded-md border-0 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={totalQty}
                      readOnly
                      disabled
                    />
                  </label>
                  <label className="text-sm font-medium leading-5 ml-[182px]">
                    <p>{`Total Cost:`} </p>
                    <input
                      placeholder="Total "
                      className="mt-3 block w-[90px] h-12 rounded-md border-0 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={`${overallTotal.toFixed(2)} €`}
                      readOnly
                      disabled
                    />
                  </label>
                </div>
              </div>

              <input
                name="recipe_cost"
                type="hidden"
                value={overallTotal.toFixed(2)}
              />
              <div className="flex">
                <div className="sm:col-span-2 mt-4 w-full">
                  <label className="text-sm font-medium leading-5">
                    Preparation Recipe :
                  </label>
                  <div className="mt-1 w-[570px]">
                    <ReactQuill
                      value={changeDescription}
                      onChange={(value) => setChangeDescription(value)}
                      className="custom-quill-editor"
                    />
                  </div>
                </div>
                <div>
                  <AiFillLock className="mr-[57px] mt-12 p-3 bg-light-pink rounded-full w-12 h-12" />
                </div>
              </div>
              <div className="flex my-8 gap-x-5">
                <div>
                  <button
                    type="submit"
                    className="block w-74 rounded-md bg-light-orange px-3.5 py-2.5 text-center text-sm font-semibold text-white"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-20 ml-24">
              <p className="text-base font-medium text-gray-color">All Media</p>
              <div className="grid grid-cols-2 gap-x-4 gap-y-4 w-456 mt-5 image-container">
                {itemToEdit && Array.isArray(itemToEdit.videos)
                  ? itemToEdit.videos.map((mediaObj, index) => (
                      <div className="relative group" key={index}>
                        {/\.(png|jpg|jpeg)$/i.test(mediaObj.filename) ? (
                          <img
                            src={`${baseUrl}/${mediaObj.path}`}
                            alt={`Uploaded Image ${index}`}
                            className="w-full"
                          />
                        ) : (
                          <video
                            key={index}
                            controls
                            width="100%"
                            height="100%"
                            src={`${baseUrl}/${mediaObj.path}`}
                            alt={`Uploaded Video ${index}`}
                          />
                        )}
                        <div className="absolute bottom-0 w-full opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                          <div className="bg-dark-gray bg-opacity-90 text-white p-3 flex justify-between items-center">
                            <h5 className="font-medium text-xs leading-5">
                              {/\.(png|jpg|jpeg)$/i.test(mediaObj.filename)
                                ? `Photo ${index + 1}`
                                : `Video ${index + 1}`}
                            </h5>
                            <AiOutlineDelete
                              onClick={() =>
                                handleDeletePhoto(itemToEdit?.id, index)
                              }
                            />
                          </div>
                        </div>{" "}
                      </div>
                    ))
                  : null}

                {media.map((mediaObj, index) => (
                  <div className="relative group" key={index}>
                    {/\.(png|jpg|jpeg)$/i.test(mediaObj.name) ? (
                      <img
                        src={URL.createObjectURL(mediaObj)}
                        alt={`Uploaded Image ${index}`}
                        className="w-full"
                      />
                    ) : (
                      <video
                        key={index}
                        controls
                        width="100%"
                        height="100%"
                        src={URL.createObjectURL(mediaObj)}
                        alt={`Uploaded Video ${index}`}
                      />
                    )}
                    <div className="absolute bottom-0 w-full opacity-100 transition-opacity duration-300">
                      <div className="bg-dark-gray bg-opacity-90 text-white p-3 flex justify-between items-center">
                        <h5 className="font-medium text-xs leading-5">
                          {/\.(png|jpg|jpeg)$/i.test(mediaObj.name)
                            ? `Photo ${index + 1}`
                            : `Video ${index + 1}`}
                        </h5>
                        <AiOutlineDelete
                          onClick={() =>
                            handleDeletePhoto(itemToEdit?.id, index)
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))}

                <div className="relative h-[200px]">
                  <label htmlFor="file-upload" className="cursor-pointer">
                    <img
                      className="w-full"
                      src="/images/Menu-one.svg"
                      alt="Menu"
                    />
                    <div className="absolute text-white justify-center items-center flex bottom-0 backdrop-blur-md w-full h-full">
                      <h5 className="font-semibold leading-10 text-6xl">+</h5>
                    </div>
                  </label>
                  <input
                    type="file"
                    id="file-upload"
                    accept="video/*, .png, .svg, .jpg, .jpeg"
                    multiple
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                  />
                </div>
              </div>
            </div>
          </form>
          {loading && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
              <RotatingLines
                visible={true}
                height={80}
                width={80}
                color="#ffffff"
                strokeWidth={5}
                animationDuration={0.75}
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditTheSemiIngredient;
