import React from "react";

const RecipeDetailsTable = ({ recipe }) => {
  const ingredients = recipe?.ingredients || [];

  return (
    <div className="flex w-[650px]">
      <div className="">
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-6">
            <div className="inline-block min-w-full py-1 sm:px-6 lg:px-8">
              <div
                className={`overflow-hidden ${
                  ingredients && ingredients.length > 8
                    ? "max-h-[407px] overflow-y-auto"
                    : ""
                }`}
              >
                <table className="min-w-full border text-center text-sm">
                  <thead className="border-b text-light-orange bg-white-light">
                    <tr>
                      <th
                        scope="col"
                        className="border-r px-6 py-3 font-normal w-[150px] "
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        className="border-r px-6 py-3 font-normal w-[150px]"
                      >
                        Ingredient
                      </th>
                      <th
                        scope="col"
                        className="border-r px-6 py-3 font-normal w-[150px]"
                      >
                        Qty
                      </th>
                      <th
                        scope="col"
                        className="border-r px-6 py-3 font-normal w-[150px] "
                      >
                        Unit
                      </th>
                      <th
                        scope="col"
                        className="border-r px-6 py-3 font-normal w-[150px]"
                      >
                        Cost
                      </th>
                      <th
                        scope="col"
                        className="border-r px-6 py-3 font-normal w-[150px] "
                      >
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ingredients.map((ingredient) => (
                      <tr key={ingredient.id} className="border-b font-light">
                        <td className="whitespace-nowrap border-r px-6 py-3">
                          {ingredient.id}
                        </td>
                        <td className="whitespace-nowrap border-r px-6 py-3">
                          {ingredient.name}
                        </td>
                        <td className="whitespace-nowrap border-r px-6 py-3">
                          {ingredient.quantity}
                        </td>
                        <td className="whitespace-nowrap border-r px-6 py-3">
                          {ingredient.unit}
                        </td>
                        <td className="whitespace-nowrap border-r px-6 py-3">
                          {ingredient.ingredient_cost}
                        </td>
                        <td className="whitespace-nowrap border-r px-6 py-3">
                          {ingredient.total_cost}€
                        </td>
                      </tr>
                    ))}
                    <tr className="font-light">
                      <td colSpan="1"></td>
                      <td className="whitespace-nowrap border-r px-6 py-3 font-bold">
                        Total 
                      </td>
                      <td className="whitespace-nowrap px-6 py-3 font-bold border-r">
                        {recipe.total_unit}
                      </td>
                      <td className="whitespace-nowrap px-6 py-3 font-bold b">
                        
                      </td>
                      <td className="whitespace-nowrap px-6 py-3 font-bold">
                        
                      </td>
                       <td className="whitespace-nowrap px-6 py-3 font-bold border-l">
                        {recipe.recipe_cost}€
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipeDetailsTable;
