import { useEffect, useState } from "react";

import { FiEdit } from "react-icons/fi";
import MyProfile from "../../components/MyProfile/MyProfile";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showUser } from "../../store/slices/authSlice";
import { AiOutlineArrowLeft } from "react-icons/ai";

const Profile = () => {
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.user);

  useEffect(() => {
    if (!userData) {
      dispatch(showUser());
    }
  }, [dispatch, userData]);

  const handleIconMouseEnter = (iconName) => {
    setHoveredIcon(iconName);
  };

  const handleIconMouseLeave = () => {
    setHoveredIcon(null);
  };

  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <div className="home-contianer">
      <div className="bg-light-pink h-89">
        <div className="container flex items-center">
          <div className="flex items-center cursor-pointer" onClick={handleGoBack}>
            <AiOutlineArrowLeft className="mr-2" />
            <p className="text-xl lg:text-xl xs:text-base font-normal">
              Go back
            </p>
          </div>
          <div className="flex-1 text-center">
            <h1 className="text-xl font-normal">My Profile</h1>
          </div>
          <Link
            to="/edit-profile"
            className="rounded-full p-3 bg-light-orange ml-8"
          >
            <FiEdit className="text-white w-4 h-4  lg:w-4 lg:h-4 xs:w-3 xs:h-3" />
          </Link>
        </div>
      </div>
      <MyProfile userData={userData} />
    </div>
  );
};

export default Profile;
