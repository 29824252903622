import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteClient,
  getClient,
  updateClient,
  getAllClients,
} from "../../store/slices/apiClientSlice";

const EditTheClient = () => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const clients = useSelector((state) => state.apiClient?.data) || [];

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getClient(id));
      } catch (error) {
        console.error("Error fetching client data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, id]);

  useEffect(() => {
    if (clients === undefined || clients.length === 0) {
      dispatch(getAllClients());
      return;
    }

    const selectedClient = clients.find((client) => client.id === parseInt(id));

    if (selectedClient) {
      setName(selectedClient.name);
      setPhoneNumber(selectedClient.phone_number);
      setComment(selectedClient.comment);
    }
  }, [clients, dispatch, id]);

  const handleEditClient = async (e) => {
    e.preventDefault();

    if (!name || name.trim() === "") {
      console.error("Name is required");
      return;
    }

    const clientData = {
      name,
      phoneNumber,
    };

    try {
      await dispatch(updateClient({ id, clientData }));
      toast.success("Changes saved successfully!");
    } catch (error) {
      toast.error(`Edit failed: ${error.message}`);
    }
  };

  return (
    <>
      <div className="container flex justify-center">
        <div className="mt-8">
          <div className="flex justify-center text-center">
            <h2 className="text-3xl font-semibold leading-10">
              Edit Client Info
            </h2>
          </div>
          <form onSubmit={handleEditClient}>
            <div className="mt-10 w-100">
              <div className="mt-5">
                <label>Name</label>
                <input
                  type="text"
                  id="name"
                  className="border border-gray-300 text-gray-900 mt-1 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Enter client's name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="mt-5">
                <label>Phone number</label>
                <input
                  type="tel"
                  id="phonenumber"
                  className="border border-gray-300 text-gray-900 mt-1 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Enter client's phone number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
              <div className="mt-8">
                <button
                  type="submit"
                  className="block w-full rounded-md bg-light-orange px-3.5 py-2.5 text-center text-sm font-semibold text-white"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditTheClient;
