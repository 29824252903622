import React from "react";
import CreateRecipe from "../../components/CreateRecipe/CreateRecipe";
import ActionsBar1 from "../../components/ActionsBar1/ActionsBar1";
import { AiOutlineArrowLeft } from "react-icons/ai";

const Create = () => {
  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <div className="edit-container">
      <div className="flex justify-center bg-light-pink h-89">
        <div className="flex items-center text-center justify-between w-1240">
          <div className="flex cursor-pointer" onClick={handleGoBack}>
            <AiOutlineArrowLeft className="mr-2 mt-2 lg:mr-2 lg:mt-2 lg:ml-0 xs:mt-1 xs:mr-1 xs:ml-5" />
            <p className="text-xl font-normal lg:text-xl xs:text-base cursor-pointer">
              Go back
            </p>
          </div>
          <h1 className="text-xl font-normal mr-10 lg:text-xl lg:mr-10 xs:mr-58 xs:text-base">
            Create Recipe
          </h1>
          <ActionsBar1 />
        </div>
      </div>
      <div className="flex  ">
        <div className="container">
          <div className="w-full flex">
            <div className=" ">
              <CreateRecipe />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
