import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";

export const createClient = createAsyncThunk(
  "api/createClient",
  async ({ name, phoneNumber }) => {
    const response = await axiosInstance.post(`/clients/createClients`, {
      name,
      phoneNumber,
    });
    return response.data;
  }
);

export const getClient = createAsyncThunk("api/getClient", async (clientId) => {
  const response = await axiosInstance.get(`/clients/getClient/${clientId}`);
  return response.data;
});

export const getAllClients = createAsyncThunk("api/getAllClients", async () => {
  const response = await axiosInstance.get("/clients/getAllClients");

  return response.data;
});

export const deleteClient = createAsyncThunk(
  "api/deleteClient",
  async (clientId) => {
    const response = await axiosInstance.delete(
      `/clients/deleteClient/${clientId}`
    );
    return response.data;
  }
);

export const updateClient = createAsyncThunk(
  "api/updateClient",
  async ({ id, clientData }) => {
    try {
      const response = await axiosInstance.put(
        `/clients/updateClient/${id}`,
        clientData
      );
      return { id, ...clientData };
    } catch (error) {
      throw error;
    }
  }
);

export const addComment = createAsyncThunk(
  "api/addComment",
  async ({ id, comment }) => {
    try {
      const response = await axiosInstance.put(`/clients/addComment/${id}`, {
        comment,
      });
      return { id, comment };
    } catch (error) {
      throw error;
    }
  }
);

const apiClientSlice = createSlice({
  name: "apiClient",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createClient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createClient.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(createClient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getClient.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(getAllClients.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(deleteClient.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(addComment.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      });
  },
});

export default apiClientSlice.reducer;
