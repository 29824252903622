import React, { useState, useEffect, useRef } from "react";
import { GoFilter } from "react-icons/go";
import { GoPlus } from "react-icons/go";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setFilteredRecipe } from "../../store/slices/filterSlice";
import { PiColumns, PiRows } from "react-icons/pi";

const FilterSubIngredient = () => {
  const dispatch = useDispatch();

  const [addMoreOptionsVisible, setAddMoreOptionsVisible] = useState(false);

  const filterRef = useRef(null);
  const addMoreRef = useRef(null);

  const toggleAddMoreOptions = () => {
    setAddMoreOptionsVisible(!addMoreOptionsVisible);
  };

  const addMoreOptions = [
    {
      text: "Add Recipe",
      link: "/create",
    },
    {
      text: "Add Sub Ingridients",
      link: "/create-subingredient",
    },
  ];

  useEffect(() => {
    const handleGlobalClick = (event) => {
      if (
        filterRef.current &&
        !filterRef.current.contains(event.target) &&
        addMoreRef.current &&
        !addMoreRef.current.contains(event.target)
      ) {
        setAddMoreOptionsVisible(false);
      }
    };
    const handleAddMoreClick = (event) => {
      if (addMoreRef.current && !addMoreRef.current.contains(event.target)) {
        setAddMoreOptionsVisible(false);
      }
    };

    document.addEventListener("click", handleGlobalClick);
    document.addEventListener("click", handleAddMoreClick);

    return () => {
      document.removeEventListener("click", handleGlobalClick);
      document.removeEventListener("click", handleAddMoreClick);
    };
  }, []);

  return (
    <div className="flex justify-center">
      <div className="flex justify-between my-4 w-1240">
        <div className="flex justify-end items-center w-full ">
          <div className="flex justify-center items-center">
            <Link to="/ingredients" className="cursor-pointer">
              <div className="text-sm text-[#C7C4C2] bg-[#F5F1EE] rounded-md py-2 px-5 hover:bg-light-orange hover:text-white transition duration-300 ease-in-out">
                Ingredients
              </div>
            </Link>
          </div>
          <div className="flex justify-center items-center">
            <Link to="/home" className="cursor-pointer">
              <div className="text-sm ml-6 mr-4 text-[#C7C4C2] bg-[#F5F1EE] rounded-md p-2 px-8 hover:bg-light-orange hover:text-white transition duration-300 ease-in-out">
                Recipes
              </div>
            </Link>
          </div>

          <div className="flex justify-center items-center">
            <div
              className={`flex mr-2 cursor-pointer${
                addMoreOptionsVisible ? " justify-center" : ""
              }`}
              onClick={toggleAddMoreOptions}
              ref={addMoreRef}
            >
              <img
                src="images/Cart-add.svg"
                className="cursor-pointer transition duration-300 ease-in-out hover:scale-75 w-[45px]"
                alt="Add More"
              />
              {addMoreOptionsVisible && (
                <div className="absolute bg-white border border-gray-300 mt-14 rounded-md p-2 w-172 lg:w-172 z-10 ">
                  {addMoreOptions.map((option) =>
                    option.link ? (
                      <Link to={option.link} key={option.text}>
                        <div className="flex justify-center hover:bg-light-orange hover:rounded-md hover:text-white hover:w-full text-center text-gray-text text-xs w-full py-2 cursor-pointer z-10">
                          {option.icon} {option.text}
                        </div>
                      </Link>
                    ) : (
                      <div
                        key={option.text}
                        className="flex justify-center hover:bg-light-orange hover:rounded-md hover:text-white hover:w-full text-center text-gray-text text-xs w-full py-2 cursor-pointer"
                      >
                        {option.icon} {option.text}
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterSubIngredient;
