import RecipeDetails from "../../components/RecipeDetails/RecipeDetails";
import { useParams } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";

const Details = () => {
  const { id } = useParams();
  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <div className="edit-container">
      <div className="flex justify-center bg-light-pink h-89">
        <div className="flex items-center text-center   w-1240">
          <div className="flex" onClick={handleGoBack}>
            <AiOutlineArrowLeft className="mr-2 mt-2 lg:mr-2 lg:mt-2 lg:ml-0 xs:mt-1 xs:mr-1 xs:ml-5" />
            <p className="text-xl font-normal lg:text-xl xs:text-base cursor-pointer">
              Go back
            </p>
          </div>
          <div className="flex-1 text-center justify-center items-center">
            {" "}
            <h1 className="text-xl font-normal mr-10 lg:text-xl xs:text-base lg:mr-10 xs:mr-58">
              Recipe Details
            </h1>
          </div>
        </div>
      </div>

      <div className="flex">
        <div className="container">
          <div className="w-full flex ">
            <div className=" ">
              <RecipeDetails id={id} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
