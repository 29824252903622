import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSemiIngredients } from "../../store/slices/apiSemiIngredient";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { RiFileExcel2Fill } from "react-icons/ri";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SemiIngredientDetailsTable from "../SemiIngredientDetailsTable/SemiIngredeintDetailsTable";
import CustomCarousel from "../CarouselExample/CarouselExample";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const DetailsOfSemiIngredient = () => {
  const { id } = useParams();

  const dataSubIngredients = useSelector(
    (state) => state.apiSemiIngredients.data
  );
  const dispatch = useDispatch();
  const [dataReady, setDataReady] = useState(false);

  const [subIngredient, setSubIngredient] = useState([]);
  const foundSemiIngredient =
    dataSubIngredients && dataSubIngredients.find((r) => r.id === parseInt(id));

  useEffect(() => {
    dispatch(getSemiIngredients())
      .then(() => {
        setDataReady(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setDataReady(true);
      });
  }, []);

  useEffect(() => {
    if (foundSemiIngredient) {
      const filteredPhotos =
        Array.isArray(foundSemiIngredient?.videos) &&
        foundSemiIngredient.videos.length > 0
          ? foundSemiIngredient.videos.filter((photo) =>
              /\.(png|jpg|jpeg)$/i.test(photo.filename)
            )
          : [];

      let tempArray = filteredPhotos?.map((photo) => ({
        ...photo,
        url: photo?.url?.replaceAll(" ", "%20"),
      }));

      let temporaryObject = {
        ...foundSemiIngredient,
        photos: tempArray || [],
      };

      setSubIngredient(temporaryObject);
    }
  }, [foundSemiIngredient]);

  if (!dataReady) {
    return <div>Loading...</div>;
  }

  if (!subIngredient) {
    return <div>Sub Ingredient not found</div>;
  }

  const handleGoBack = () => {
    window.history.back();
  };

  const handleExportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sub Ingredients Table");

    const customHeaders = [
      "Ingredient",
      "Quantity",
      "Unit",
      "Cost",
      "Total Cost",
      "Sub Ingredient Cost",
      "Total Unit",
    ];

    const headerRow = worksheet.addRow(customHeaders);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "D0D5DD" },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    if (subIngredient && subIngredient.title) {
      subIngredient.title.forEach((entry) => {
        const { quantity, unit, ingredients, cost, total_cost } = entry;

        worksheet.addRow([
          ingredients.value,
          quantity,
          unit,
          cost,
          total_cost,
          subIngredient.cost,
          subIngredient.total_unit,
        ]);
      });
    }

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "subIngredientTable.xlsx");
  };

  return (
    <>
      <div className="flex justify-center bg-light-pink h-89">
        <div className="flex items-center text-center justify-between w-1240">
          <div className="flex cursor-pointer" onClick={handleGoBack}>
            <AiOutlineArrowLeft className="mr-2 mt-2 lg:mr-2 lg:mt-2 lg:ml-0 xs:mt-1 xs:mr-1 xs:ml-5" />
            <p className="text-xl font-normal lg:text-xl xs:text-base cursor-pointer">
              Go back
            </p>
          </div>
          <div className="flex-1 text-center justify-center items-center">
            {" "}
            <h1 className="text-xl font-normal mr-10 lg:text-xl  lg:mr-10 xs:mr-14 xs:text-base">
              Sub Ingredients Details
            </h1>
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <div className="contianer flex">
          <div className="items-center justify-center flex ">
            <div className="mx-auto mt-16 max-w-xl sm:mt-20">
              <p className="font-semibold text-4xl text-black leading-10 mb-5 ml-2 mt-20">
                {subIngredient.ingredients}
              </p>

              <div className="container  ">
                <button
                  className="flex items-center gap-2 p-2 ml-2 mb-2 border rounded-lg"
                  onClick={handleExportToExcel}
                >
                  Export Table
                  <RiFileExcel2Fill className="text-green-800" />
                </button>
              </div>

              <SemiIngredientDetailsTable subIngredient={subIngredient} />

              <div className="mb-10 sm:col-span-2 mt-9 ml-2">
                <div>
                  <table className="w-[630px] border text-sm">
                    <thead className="border-b text-light-orange bg-white-light">
                      <th
                        scope="col"
                        className="border-r px-6 py-3 font-normal w-[150px] text-left cursor-pointer"
                      >
                        Description
                      </th>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          colSpan="2"
                          style={{
                            whiteSpace: "pre-line",
                            maxWidth: "653px",
                            textOverflow: "ellipsis",
                            maxHeight: "130px",
                            overflow: "auto",
                            userSelect: "none",
                          }}
                          contentEditable={false}
                        >
                          {subIngredient && (
                            <ReactQuill
                              theme={null}
                              style={{ border: "none" }}
                              value={subIngredient.description}
                              modules={{}}
                            />
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[100px] ml-44 lg:ml-44 xs:ml-0">
            {subIngredient?.videos && (
              <CustomCarousel dataSubIngredients={dataSubIngredients} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsOfSemiIngredient;
