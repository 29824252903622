import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { RiDeleteBin4Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSemiIngredient,
  getSemiIngredients,
} from "../../store/slices/apiSemiIngredient";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Pagination from "../Pagination/Pagination";
import DeleteSubIngredient from "../DeleteSubIngredient/DeleteSubIngredient";
import FilterSubIngredient from "../FilterSubIngredient/FilterSubIngredient";

const SubIngredientCard = () => {
  const [editHovered, setEditHovered] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [hoveredDeleteButton, setHoveredDeleteButton] = useState(null);
  const [deleteSubIngredientId, setDeleteSubIngredientId] = useState(null);
  const dataSubIngredients = useSelector(
    (state) => state.apiSemiIngredients.data
  );
  const subIngredientsArray = Array.isArray(dataSubIngredients)
    ? dataSubIngredients
    : [];
  const dispatch = useDispatch();
  const baseUrl = "https://backrecrez.bbros.al";

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentSubIngredients = subIngredientsArray.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  useEffect(() => {
    dispatch(getSemiIngredients());
  }, []);

  const handleEditMouseEnter = (itemId) => {
    setEditHovered(itemId);
  };

  const handleEditMouseLeave = () => {
    setEditHovered(null);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDeleteClick = (itemId) => {
    setShowDeleteModal(true);
    setDeleteSubIngredientId(itemId);

    dispatch(deleteSemiIngredient(itemId))
      .unwrap()
      .then(() => {
        console.log("Deleted successfully");
      })
      .catch((error) => {
        console.error("Delete failed:", error);
      });
  };

  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <>
      <div className="flex justify-center bg-light-pink h-89">
        <div className="flex items-center text-center justify-between w-1240">
          <div className="flex cursor-pointer" onClick={handleGoBack}>
            <AiOutlineArrowLeft className="mr-2 mt-2 lg:mr-2 lg:mt-2 lg:ml-0 xs:mt-1 xs:mr-1 xs:ml-5" />
            <p className="text-xl font-normal lg:text-xl xs:text-base">
              Go back
            </p>
          </div>
          <div className="flex-1 text-center justify-center items-center">
            {" "}
            <h1 className="text-xl font-normal mr-10 lg:text-xl  lg:mr-10 xs:mr-14 xs:text-base">
              Sub Ingredients
            </h1>
          </div>
        </div>
      </div>
      <FilterSubIngredient />
      <div className="flex justify-center mt-14">
        <div className="grid grid-cols-4 gap-8">
          {currentSubIngredients.map((subIngredient) => {
            const videoArray = Array.isArray(subIngredient?.videos)
              ? subIngredient?.videos
              : [];

            const filteredPhotos = videoArray.filter((photo) =>
              /\.(png|jpg|jpeg)$/i.test(photo.filename)
            );

            const img = `${baseUrl}/${
              filteredPhotos?.length !== 0
                ? filteredPhotos[0].path.replaceAll(" ", "%20")
                : ""
            }`;

            return (
              <div key={subIngredient.id} className="pb-10 pt-3">
                <Link to={`/details-subingredient/${subIngredient.id}`}>
                  <div class="relative max-w-xs overflow-hidden bg-cover bg-no-repeat">
                    <img
                      src={img}
                      className="w-[286px] h-56 max-w-xs transition duration-300 ease-in-out hover:scale-110 object-cover hover:opacity-100"
                      alt={`SubIngredient Photo`}
                      onError={() => console.log("Image failed to load")}
                    />
                  </div>
                </Link>
                <div>
                  <div className="flex w-286 justify-between mt-5">
                    <h5 className="text-xl font-semibold leading-8 tracking-tight text-gray-900">
                      <span>{subIngredient.ingredients}</span>
                    </h5>
                    <div className="flex cursor-pointer">
                      <Link to={`/edit-subingredient/${subIngredient.id}`}>
                        <div
                          className={`mr-2 p-2 rounded-full ${
                            editHovered === subIngredient.id
                              ? "bg-light-orange"
                              : "bg-light-pink"
                          }`}
                          onMouseEnter={() =>
                            handleEditMouseEnter(subIngredient.id)
                          }
                          onMouseLeave={handleEditMouseLeave}
                        >
                          <FiEdit
                            className={`text-gray-color ${
                              editHovered === subIngredient.id
                                ? "text-white"
                                : ""
                            }`}
                          />
                        </div>
                      </Link>
                      <div
                        className={`mr-2 p-2 rounded-full ${
                          hoveredDeleteButton === subIngredient.id
                            ? "bg-light-orange"
                            : "bg-light-pink"
                        }`}
                        onMouseEnter={() =>
                          setHoveredDeleteButton(subIngredient.id)
                        }
                        onMouseLeave={() => setHoveredDeleteButton(null)}
                        onClick={() => {
                          setShowDeleteModal(true);
                          setDeleteSubIngredientId(subIngredient.id);
                        }}
                      >
                        <RiDeleteBin4Line
                          className={`text-gray-color ${
                            hoveredDeleteButton === subIngredient.id
                              ? "text-white"
                              : ""
                          }`}
                        />
                      </div>

                      {showDeleteModal &&
                        deleteSubIngredientId === subIngredient.id && (
                          <DeleteSubIngredient
                            onClose={() => setShowDeleteModal(false)}
                            itemId={deleteSubIngredientId}
                            handleDeleteClick={handleDeleteClick}
                          />
                        )}
                    </div>
                  </div>

                  <p className="text-gray-color font-normal text-base leading-6 mt-2 ">
                    Last modified{" "}
                    {new Date(subIngredient.created_at).toLocaleString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour12: false,
                      }
                    )}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Pagination
        activePage={currentPage}
        onPageChange={handlePageChange}
        itemsPerPage={itemsPerPage}
        totalItems={subIngredientsArray.length}
      />
    </>
  );
};

export default SubIngredientCard;
