import React from "react";

const TextPopup = ({ text, onClose }) => {
  return (
    <div className="popup">
      <div className="popup-content">
        <div className="relative bg-white rounded-lg shadow">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <button
              onClick={onClose}
              className="text-xl font-semibold text-black"
            >
              Close
            </button>
          </div>

          <div
            className="p-4 md:p-5 space-y-4"
            style={{
              whiteSpace: "pre-line",
              maxWidth: "653px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "table-cell",
            }}
          >
            <div className="text-area text-base leading-relaxed text-gray-500 w-[600px] popup-container">
              {text}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextPopup;
