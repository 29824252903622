import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiFillLock } from "react-icons/ai";
import {
  createDataRecipes,
  fetchDataRecipes,
} from "../../store/slices/apiRecipes";
import { Link, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getIngredients } from "../../store/slices/getIngredients";
import { getSupplier } from "../../store/slices/getSupplier";
import DropdownIngredientsCreate from "../DropdownIngredientsCreate/DropdownIngredientsCreate";
import DropdownRecipe from "../DropdownRecipe/DropdownRecipe";
import { RotatingLines } from "react-loader-spinner";

const CreateRecipe = () => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [preparation, setPreparatinon] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedIngredients, setSelectedIngredients] = useState("");
  const [selectedSuppliers, setSelectedSuppliers] = useState("");
  const [media, setMedia] = useState([]);
  const navigate = useNavigate();
  const [visibilityOption, setVisibilityOption] = useState(1);
  const [loading, setLoading] = useState(false);

  const [recipeIngredients, setRecipeIngredients] = useState([
    {
      quantity: "",
      unit: "",
      ingredients: "",
      ingredient_cost: "",
      total_cost: "",
    },
  ]);
  const [categoryDropdown, setCategoryDropdown] = useState([
    { isOpen: true, value: selectedCategory },
  ]);

  const [ingredientsDropdown, setIngredientsDropdown] = useState(
    recipeIngredients.map(() => ({ isOpen: false, selectedOption: "" }))
  );

  const [selectedIngredientsForRow, setSelectedIngredientsForRow] = useState(
    recipeIngredients.map(() => "")
  );

  const [suppliersDropdown, setSuppliersDropdown] = useState(
    recipeIngredients.map(() => ({ isOpen: false, selectedOption: "" }))
  );

  const [selectedSuppliersForRow, setSelectedSuppliersForRow] = useState(
    recipeIngredients.map(() => "")
  );

  const ingredients = useSelector((state) => state.apiGetIngredients.data);

  useEffect(() => {
    dispatch(fetchDataRecipes());
    dispatch(getIngredients());
    dispatch(getSupplier());
  }, []);

  const handleFileUpload = (event) => {
    const files = event.target.files;
    setMedia([...media, ...Array.from(files)]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("categories", selectedCategory);
    formData.append("visibility", visibilityOption);
    formData.append("preparation", preparation);
    formData.append("created_by", localStorage.getItem("userId"));

    media.forEach((item) => formData.append("videos", item));

    const ingredientsData = recipeIngredients.map((ingredient, index) => ({
      quantity: ingredient.quantity,
      unit: ingredientUnits[index],
      ingredients: ingredient.ingredients,
      ingredient_cost: ingredientCosts[index],
      total_cost: calculatedTotal[index],
    }));

    formData.append("recipeDetails", JSON.stringify(ingredientsData));
    formData.append("recipe_cost", overallTotal.toFixed(2));
    formData.append("total_unit", totalQty);

    for (const pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    try {
      await dispatch(createDataRecipes(formData));
      setTitle("");
      setDescription("");
      setPreparatinon("");
      setRecipeIngredients([
        {
          quantity: "",
          unit: "",
          ingredients: "",
          ingredient_cost: "",
          total_cost: "",
        },
      ]);
      setMedia([]);
      navigate("/home");
    } catch (error) {
      console.log("Error submitting data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCategory = (index, option) => {
    setSelectedCategory(option);
    setCategoryDropdown((prevDropdowns) =>
      prevDropdowns.map((dropdown, i) =>
        i === index ? { ...dropdown, value: option, isOpen: false } : dropdown
      )
    );
  };

  const handleIngredients = (index, option, type) => {
    setSelectedIngredients(option);
    setSelectedIngredientType(type);

    const updatedSelectedIngredients = [...selectedIngredientsForRow];
    updatedSelectedIngredients[index] = option;
    setSelectedIngredientsForRow(updatedSelectedIngredients);

    const updatedDropdowns = [...ingredientsDropdown];
    updatedDropdowns[index] = { ...updatedDropdowns[index], isOpen: false };
    setIngredientsDropdown(updatedDropdowns);

    const updatedIngredients = [...recipeIngredients];
    updatedIngredients[index] = {
      ...updatedIngredients[index],
      ingredients: option,
    };
    setRecipeIngredients(updatedIngredients);
  };

  const handleAddField = () => {
    setRecipeIngredients([
      ...recipeIngredients,
      { quantity: "", unit: "", ingredients: "", cost: "", total_cost: "" },
    ]);

    setIngredientsDropdown((prevDropdowns) => [
      ...prevDropdowns,
      { isOpen: true, value: selectedIngredients },
    ]);

    setSuppliersDropdown((prevDropdowns) => [
      ...prevDropdowns,
      { isOpen: true, value: selectedSuppliers },
    ]);
  };

  const handleRemoveField = (index) => {
    const updatedIngredients = [...recipeIngredients];
    updatedIngredients.splice(index, 1);
    setRecipeIngredients(updatedIngredients);
  };

  const categories = [
    "Appetizers",
    "Entrees",
    "Pasta",
    "Sandwiches",
    "Burgers",
    "Pizza",
    "Sea Food",
    "Desserts",
    "Vegetarian",
  ];

  const handleQuillChange = (value) => {
    setDescription(value);
  };

  const [ingredientCosts, setIngredientCosts] = useState(
    recipeIngredients.map(() => "")
  );
  const [ingredientUnits, setIngredientUnits] = useState(
    recipeIngredients.map(() => "")
  );
  const [calculatedTotal, setCalculatedTotal] = useState(
    recipeIngredients.map(() => "")
  );

  const [ingredientTotalCosts, setIngredientTotalCosts] = useState(
    recipeIngredients.map(() => 0)
  );
  const [overallTotal, setOverallTotal] = useState(0);

  const handleIngredientCostAndUnit = ({ cost, unit }, index) => {
    const updatedCosts = [...ingredientCosts];
    updatedCosts[index] = parseFloat(cost) || 0;
    setIngredientCosts(updatedCosts);

    const updatedUnits = [...ingredientUnits];
    updatedUnits[index] = unit;
    setIngredientUnits(updatedUnits);

    const quantity = parseFloat(recipeIngredients[index].quantity) || 0;
    const totalCost = (quantity * updatedCosts[index]).toFixed(2);

    const updatedTotalCosts = [...ingredientTotalCosts];
    updatedTotalCosts[index] = parseFloat(totalCost) || 0;
    setIngredientTotalCosts(updatedTotalCosts);

    const newOverallTotal = updatedTotalCosts.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    setOverallTotal(newOverallTotal);
  };

  const handleQuantityChange = (e, index) => {
    const quantity = parseFloat(e.target.value) || 0;
    const cost = parseFloat(ingredientCosts[index]) || 0;

    const total = (quantity * cost).toFixed(2);

    const updatedCalculatedTotal = [...calculatedTotal];
    updatedCalculatedTotal[index] = total;
    setCalculatedTotal(updatedCalculatedTotal);

    const updatedIngredients = [...recipeIngredients];
    updatedIngredients[index].quantity = e.target.value;
    setRecipeIngredients(updatedIngredients);

    const updatedTotalCosts = [...ingredientTotalCosts];
    updatedTotalCosts[index] = parseFloat(total) || 0;
    setIngredientTotalCosts(updatedTotalCosts);

    const newOverallTotal = updatedTotalCosts.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    setOverallTotal(newOverallTotal);
  };

  const [selectedIngredientType, setSelectedIngredientType] = useState("");

  const handleCheckboxClick = (value) => {
    setVisibilityOption(value);
  };

  const [totalQty, setTotalQty] = useState("");

  useEffect(() => {
    const newTotalQty = recipeIngredients.reduce(
      (accumulator, ingredient, index) => {
        const unit = ingredientUnits[index]?.toLowerCase();

        if (unit && ["kg", "gr", "g"].includes(unit)) {
          const quantity = parseFloat(ingredient.quantity);

          if (!isNaN(quantity)) {
            return accumulator + quantity;
          } else {
            console.log(`Skipping invalid quantity for ${unit}`);
          }
        } else {
          console.log(`Skipping ${ingredient.quantity} ${unit}`);
        }

        return accumulator;
      },
      0
    );

    setTotalQty(newTotalQty);
  }, [recipeIngredients, ingredientUnits]);

  return (
    <div className="flex justify-center mt-20">
      <div className="items-center justify-center flex mt-20">
        <div className="mx-auto mt-16 max-w-xl sm:mt-20">
          <form onSubmit={handleSubmit}>
            <input
              name="name"
              id="name"
              placeholder="Type the Recipe Name..."
              className="mb-8 block w-[570px] h-46 rounded-md border-0 px-3.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              value={title}
              required
              onChange={(e) => setTitle(e.target.value)}
            />
            <div className="">
              {categoryDropdown.map((dropdown, index) => (
                <DropdownRecipe
                  key={index}
                  options={categories}
                  selectedOption={dropdown.value}
                  isOpen={dropdown?.isOpen || false}
                  onSelect={(option) => handleCategory(index, option)}
                  toggleDropdown={() =>
                    setCategoryDropdown((prevDropdowns) =>
                      prevDropdowns.map((d, i) =>
                        i === index ? { ...d, isOpen: !d.isOpen } : d
                      )
                    )
                  }
                />
              ))}
            </div>
            <div>
              <div className="grid grid-rows-1 gap-x-2 sm:grid-rows-1">
                {recipeIngredients.map((ingredient, index) => (
                  <div key={index} className="flex gap-x-2 mt-5">
                    <label className="text-sm font-medium leading-5">
                      <div className="flex mb-2 gap-x-2 text-sm font-medium leading-5">
                        <p className="">Ingredient</p>
                        <Link
                          to="/ingredient"
                          class="text-white bg-yellow-500 text-xs rounded-lg px-2.5 py-1  "
                        >
                          Add
                        </Link>
                      </div>
                      <DropdownIngredientsCreate
                        ingredients={ingredients}
                        selectedOption={selectedIngredientsForRow[index]}
                        isOpen={ingredientsDropdown[index].isOpen || false}
                        onSelect={(option) => handleIngredients(index, option)}
                        toggleDropdown={() =>
                          setIngredientsDropdown((prevDropdowns) =>
                            prevDropdowns.map((d, i) =>
                              i === index ? { ...d, isOpen: !d.isOpen } : d
                            )
                          )
                        }
                        setIngredientCostAndUnit={(costUnit) =>
                          handleIngredientCostAndUnit(costUnit, index)
                        }
                        setIngredientType={(type) =>
                          setSelectedIngredientType(type)
                        }
                      />
                    </label>

                    <label className="text-sm font-medium leading-5">
                      Qty
                      <input
                        name={`qty-${index}`}
                        placeholder="Qty"
                        className="mt-3 block w-[60px] h-12 rounded-md border-0 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={ingredient.quantity}
                        onChange={(e) => handleQuantityChange(e, index)}
                      />
                    </label>

                    <label className="text-sm font-medium leading-5">
                      Unit
                      <input
                        name={`unit-${index}`}
                        placeholder="Unit"
                        className="mt-3 block w-[70px] h-12 rounded-md border-0 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={ingredientUnits[index]}
                        onChange={(e) => {
                          const updatedUnits = [...ingredientUnits];
                          updatedUnits[index] = e.target.value;
                          setIngredientUnits(updatedUnits);
                        }}
                        disabled
                      />
                    </label>

                    <label className="text-sm font-medium leading-5">
                      <p className=""> Cost</p>
                      <input
                        name={`cost-${index}`}
                        placeholder="Cost"
                        className="mt-3 block w-[90px] h-12 rounded-md border-0 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={ingredientCosts[index]}
                        onChange={(e) => {
                          const updatedCosts = [...ingredientCosts];
                          updatedCosts[index] = e.target.value;
                          setIngredientCosts(updatedCosts);
                        }}
                        disabled
                      />
                    </label>
                    <label className="text-sm font-medium leading-5">
                      <p className=""> Total</p>
                      <input
                        name={`total-cost-${index}`}
                        placeholder="Total "
                        className="mt-3 block w-[90px] h-12 rounded-md border-0 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={calculatedTotal[index]}
                        readOnly
                        disabled
                      />
                    </label>

                    <div className="flex items-end gap-2">
                      {index === recipeIngredients.length - 1 && (
                        <button
                          type="button"
                          className="ml-2 p-3 bg-light-pink rounded-full w-12 h-12"
                          onClick={handleAddField}
                        >
                          <img
                            src="images/add-icon.svg"
                            alt="Add"
                            className="w-6 h-6"
                          />
                        </button>
                      )}
                      <button
                        type="button"
                        className="p-3 bg-light-pink rounded-full w-12 h-12"
                        onClick={() => handleRemoveField(index)}
                      >
                        <img
                          src="images/delete-icon.svg"
                          alt="Delete"
                          className="w-6 h-6"
                        />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className=" w-full ">
              <div className="sm:col-span-2 mt-4 w-full flex ml-[170px] ">
                <label className="text-sm font-medium leading-5">
                  <p>{`Total Qty`} </p>
                  <input
                    placeholder="Total "
                    className="mt-3 block w-[60px] h-12 rounded-md border-0 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={totalQty}
                    readOnly
                    disabled
                  />
                </label>
                <label className="text-sm font-medium leading-5 ml-[182px]">
                  <p>{`Total Cost`} </p>
                  <input
                    placeholder="Total "
                    className="mt-3 block w-[90px] h-12 rounded-md border-0 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={`${overallTotal.toFixed(2)} €`}
                    readOnly
                    disabled
                  />
                </label>
              </div>
            </div>

            <input
              name="recipe_cost"
              type="hidden"
              value={overallTotal.toFixed(2)}
            />
            <label className="text-sm font-medium leading-5">
              <p className="mt-6">Description</p>
              <textarea
                rows="5"
                id="description"
                required
                className="mb-8 block w-570 mt-2 rounded-md border-0 px-3.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 lg:w-[570px] xs:w-310 outline-none p-4"
                value={preparation}
                onChange={(e) => setPreparatinon(e.target.value)}
              />
            </label>
            <div className="flex w-full">
              <div className="sm:col-span-2  w-full">
                <label className="text-sm font-medium leading-5">
                  Preparation Recipe
                </label>
                <div className="mt-1 w-[570px]  ">
                  <ReactQuill
                    value={description}
                    onChange={handleQuillChange}
                    theme="snow"
                    className="custom-quill-editor"
                  />
                </div>
              </div>
              <div>
                <AiFillLock className="ml-4 mt-12 p-3 bg-light-pink rounded-full w-12 h-12" />
              </div>
            </div>
            <div className="flex my-8 gap-x-5 ">
              <div className="">
                <button
                  type="submit"
                  className="block w-74 rounded-md bg-light-orange px-3.5 py-2.5 text-center text-sm font-semibold text-white"
                >
                  Add
                </button>
              </div>

              <div className="flex items-center ">
                <input
                  id="default-checkbox"
                  type="checkbox"
                  value="aktive"
                  checked={visibilityOption === 1}
                  onChange={() => handleCheckboxClick(1)}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-checkbox"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Aktive
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="checked-checkbox"
                  type="checkbox"
                  value="pasive"
                  checked={visibilityOption === 0}
                  onChange={() => handleCheckboxClick(0)}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="checked-checkbox"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Pasive
                </label>
              </div>
            </div>
          </form>
          {loading && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
              <RotatingLines
                visible={true}
                height={80}
                width={80}
                color="#ffffff"
                strokeWidth={5}
                animationDuration={0.75}
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          )}
        </div>
      </div>
      <div className="mt-24 ml-[200px]">
        <div className="grid grid-cols-2 gap-x-4 gap-y-4 w-456 image-container">
          {media?.map((file, index) => (
            <div className="relative group" key={index}>
              {file.type.startsWith("image/") ? (
                <img
                  className="w-[200px] h-[200px] object-cover "
                  src={URL.createObjectURL(file)}
                  alt={`Uploaded Image ${index}`}
                />
              ) : (
                <video
                  className="w-[200px] h-[200px] object-cover "
                  src={URL.createObjectURL(file)}
                  controls
                />
              )}
              <div className="absolute bottom-0 w-full opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <div className="bg-dark-gray bg-opacity-90 text-white p-3 flex justify-between items-center">
                  <h5 className="font-medium text-xs leading-5">
                    {file.type.startsWith("image/")
                      ? `Photo ${index + 1}`
                      : `Video ${index + 1}`}
                  </h5>
                  <button
                    type="button"
                    className="cursor-pointer"
                    onClick={() => {
                      const newFiles = [...media];
                      newFiles.splice(index, 1);
                      setMedia(newFiles);
                    }}
                  >
                    <img src="images/delete-icon-white.svg" alt="Delete" />
                  </button>
                </div>
              </div>
            </div>
          ))}

          <div className="relative h-[200px]">
            <label
              htmlFor="file-upload"
              className="cursor-pointer plus-icon-label"
            >
              <img className="w-full" src="images/Menu-one.svg" alt="Menu" />
              <div className="absolute text-white justify-center items-center flex bottom-0 backdrop-blur-md w-full h-full">
                <h5 className="font-semibold leading-10 text-6xl justify-center mb-20">
                  +
                </h5>
              </div>
            </label>
            <input
              type="file"
              id="file-upload"
              accept=".png, .svg, .jpg, .jpeg"
              style={{ display: "none" }}
              multiple
              onChange={handleFileUpload}
            />

            <input
              type="file"
              id="video-upload"
              name="video-upload"
              accept="video/*, .png, .svg, .jpg, .jpeg"
              style={{ display: "none" }}
              multiple
              onChange={handleFileUpload}
            />
            <label htmlFor="video-upload" className="cursor-pointer">
              <div className="absolute text-white justify-center items-center flex bottom-0 backdrop-blur-md w-full h-full">
                <h5 className="font-semibold leading-10 text-6xl justify-center mb-20">
                  +
                </h5>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateRecipe;
