import EditTheReservation from "../../components/EditTheReservation/EditTheReservation";

import { AiOutlineArrowLeft } from "react-icons/ai";

const EditReservation = () => {
  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <div className="home-container">
      <div className="flex justify-center bg-light-pink h-89 mb-10">
        <div className="container flex items-center text-center">
          <div
            className="flex items-center cursor-pointer"
            onClick={handleGoBack}
          >
            <AiOutlineArrowLeft className="mr-2" />
            <p className="text-xl lg:text-lg font-normal xs:text-sm cursor-pointer">
              Go back
            </p>
          </div>
          <div className="flex-auto text-center justify-center items-center">
            {" "}
            <h1 className="text-xl lg:text-xl xs:text-base font-normal">
              Edit Reservation
            </h1>
          </div>
        </div>
      </div>
      <div className="flex  mb-10">
        <div className="container">
          <div className="w-full flex">
            <div className=" ">
              <EditTheReservation />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditReservation;
