import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataRecipes } from "../../store/slices/apiRecipes";
import { RiFileExcel2Fill } from "react-icons/ri";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import RecipeDetailsTable from "../RecipeDetailsTable/RecipeDetailsTable";
import RecipeCarousel from "../RecipeCarousel/RecipeCarousel";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const RecipeDetails = () => {
  const { id } = useParams();
  const dataRecipes = useSelector((state) => state.apiRecipes.data.recipes);
  const dispatch = useDispatch();
  const [dataReady, setDataReady] = useState(false);
  const [recipe, setRecipe] = useState();
  const [selectedSection, setSelectedSection] = useState("description");

  const handleSectionClick = (section) => {
    setSelectedSection(section);
  };

  const foundRecipe =
    dataRecipes && dataRecipes.find((r) => r.id === parseInt(id));

  useEffect(() => {
    dispatch(fetchDataRecipes())
      .then(() => {
        setDataReady(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setDataReady(true);
      });
  }, [dispatch]);

  useEffect(() => {
    if (foundRecipe) {
      const filteredPhotos =
        Array.isArray(foundRecipe?.video) && foundRecipe.video.length > 0
          ? foundRecipe.video.filter((photo) =>
              /\.(png|jpg|jpeg)$/i.test(photo.filename)
            )
          : [];

      let tempArray = filteredPhotos?.map((photo) => ({
        ...photo,
        url: photo?.url?.replaceAll(" ", "%20"),
      }));

      let temporaryObject = {
        ...foundRecipe,
        photos: tempArray || [],
      };

      setRecipe(temporaryObject);
    }
  }, [foundRecipe]);

  if (!dataReady) {
    return <div>Loading...</div>;
  }

  if (!recipe) {
    return <div>Recipe not found</div>;
  }

  const handleExportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sub Ingredients Table");

    const customHeaders = [
      "ID",
      "Ingredient",
      "Quantity",
      "Unit",
      "Cost",
      "Total Cost",
      "Recipe Cost",
      "Total Unit",
    ];

    const headerRow = worksheet.addRow(customHeaders);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "D0D5DD" },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    if (recipe && recipe.ingredients) {
      recipe.ingredients.forEach((entry) => {
        const { id, name, quantity, unit, ingredient_cost, total_cost } = entry;

        worksheet.addRow([
          id,
          name,
          quantity,
          unit,
          ingredient_cost,
          total_cost,
          recipe.recipe_cost,
          recipe.total_unit,
        ]);
      });
    }

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "subIngredientTable.xlsx");
  };

  return (
    <div className="flex justify-center mt-20">
      <div className="contianer flex">
        <div className="items-center justify-center flex ">
          <div className="mx-auto mt-16 max-w-xl sm:mt-20">
            <p className="font-semibold text-4xl text-black leading-10 mb-5 ml-2 mt-20">
              {recipe.title}
            </p>

            <p className="font-light text-2xl text-black leading-10 ml-2 mb-6">
              {recipe.categories}
            </p>

            <div className="container  ">
              <button
                className="flex items-center gap-2 p-2 ml-2 mb-2 border rounded-lg"
                onClick={handleExportToExcel}
              >
                Export Table
                <RiFileExcel2Fill className="text-green-800" />
              </button>
            </div>

            <RecipeDetailsTable recipe={recipe} />

            <div className="sm:col-span-2 mt-9 ml-2 mb-10 w-[635px]">
              <div>
                <table className=" border text-sm">
                  <thead className="border-b text-light-orange bg-white-light">
                    <tr>
                      <th
                        scope="col"
                        className={`border-r px-6 py-3 font-normal w-[326px] text-center cursor-pointer ${
                          selectedSection === "description"
                            ? "font-semibold  outline underline"
                            : ""
                        }`}
                        onClick={() => handleSectionClick("description")}
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        className={`border-r px-6 py-3 font-normal w-[326px] text-center cursor-pointer ${
                          selectedSection === "preparation"
                            ? "font-semibold outline underline"
                            : ""
                        }`}
                        onClick={() => handleSectionClick("preparation")}
                      >
                        Preparation
                      </th>
                    </tr>
                  </thead>
                  <tbody className="h-[130px] max-h-[150px] ">
                    {recipe && (
                      <>
                        <td
                          colSpan="2"
                          style={{
                            whiteSpace: "pre-line",
                            maxWidth: "653px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            wordWrap: "break-word",
                            display:
                              selectedSection === "description"
                                ? "table-cell"
                                : "none",
                          }}
                        >
                          <ReactQuill
                            theme={null}
                            style={{
                              border: "none",
                              fontSize: "16px",
                            }}
                            value={recipe.preparation}
                            modules={{}}
                          />
                        </td>
                        <td
                          colSpan="2"
                          style={{
                            whiteSpace: "pre-line",
                            maxWidth: "653px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            wordWrap: "break-word",

                            display:
                              selectedSection === "preparation"
                                ? "table-cell"
                                : "none",
                          }}
                        >
                          <ReactQuill
                            theme={null}
                            style={{
                              border: "none",
                              fontSize: "16px",
                            }}
                            value={recipe.description}
                            modules={{}}
                          />
                        </td>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[128px] ml-44 lg:ml-44 xs:ml-0">
          {recipe?.video && <RecipeCarousel dataRecipes={dataRecipes} />}
        </div>
      </div>
    </div>
  );
};

export default RecipeDetails;
