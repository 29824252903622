import React, { useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useParams } from "react-router-dom";
import EditTheSemiIngredient from "../../components/EditTheSemiIngredint/EditTheSemiIngredient";

const EditSemiIngredient = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { id } = useParams();

  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <div className={`edit-container ${isDeleteModalOpen ? "blurred" : ""}`}>
      <div className="flex justify-center bg-light-pink h-89">
        <div className="flex items-center text-center justify-between w-1240">
          <div className="flex" onClick={handleGoBack}>
            <AiOutlineArrowLeft className="mr-2 mt-2 lg:mr-2 lg:mt-2 lg:ml-0 xs:mt-1 xs:mr-1 xs:ml-5" />
            <p className="text-xl font-normal lg:text-xl xs:text-base cursor-pointer">
              Go back
            </p>
          </div>
          <div className="flex-1 text-center justify-center items-center">
            {" "}
            <h1 className="text-xl font-normal mr-10 lg:text-xl  lg:mr-10 xs:mr-14 xs:text-base">
              Edit Sub Ingredient
            </h1>
          </div>
        </div>
      </div>
      <div className="flex ">
        <div className="container">
          <div className="w-full flex">
            <div className=" ">
              <EditTheSemiIngredient id={id} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSemiIngredient;
