import { useEffect, useState } from "react";
import CreateIngredientTable from "../../components/CreateIngredientTable/CreateIngredientTable";
import { RiFileExcel2Fill } from "react-icons/ri";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import FilterIngredients from "../../components/FilterIngredients/FilterIngredients";
import {
  deleteIngredient,
  getIngredients,
} from "../../store/slices/getIngredients";
import Pagination from "../../components/Pagination/Pagination";
import { toast } from "react-toastify";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const IngredientsTable = () => {
  const [recipeCount, setRecipeCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const dispatch = useDispatch();
  const ingredients = useSelector((state) => state.apiGetIngredients.data);
  const loading = useSelector((state) => state.apiGetIngredients.loading);
  const error = useSelector((state) => state.apiGetIngredients.error);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentIngredients = Array.isArray(ingredients)
    ? ingredients.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    dispatch(getIngredients());
  }, [dispatch]);

  const handleGoBack = () => {
    window.history.back();
  };

  const handleDeleteIngredient = (ingredientId) => {
    dispatch(deleteIngredient(ingredientId))
      .unwrap()
      .then(() => {
        toast.success("Ingredient Deleted Successfully");
        dispatch(getIngredients()).catch((error) => {
          toast.error(`Refresh failed: ${error.message}`);
        });
      })
      .catch((error) => {
        toast.error(`Delete failed: ${error.message}`);
      });
  };

  const handleExportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Ingredients Table");

    const customHeaders = [
      "ID",
      "Ingredient",
      "Category",
      "Unit",
      "Cost",
      "Supplier",
    ];

    const headerRow = worksheet.addRow(customHeaders);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "D0D5DD" },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    const ingredientColumn = worksheet.getColumn(2);
    ingredientColumn.width = 20;
    const dataColumn = worksheet.getColumn(5);
    dataColumn.width = 25;
    const supplierColumn = worksheet.getColumn(6);
    supplierColumn.width = 20;
    ingredients.forEach(
      ({ id, ingredients, category, unit, cost, supplier }) => {
        worksheet.addRow([id, ingredients, category, unit, cost, supplier]);
      }
    );
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "ingredientTable.xlsx");
  };

  return (
    <div>
      <div className="bg-light-pink h-89">
        <div className="container flex items-center">
          <div
            className="flex items-center cursor-pointer"
            onClick={handleGoBack}
          >
            <AiOutlineArrowLeft className="mr-2" />
            <p className="text-xl lg:text-xl xs:text-base font-normal">
              Go back
            </p>
          </div>
          <div className="flex-1 text-center justify-center items-center">
            {" "}
            <h1 className="text-xl font-normal mr-24">Ingredients Table</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <FilterIngredients recipeCount={recipeCount} />
        <div className="flex justify-end mr-4 mt-2">
        <button
          className="flex items-center gap-2 p-2 ml-2 border rounded-lg"
          onClick={handleExportToExcel}
        >
          Export Table
          <RiFileExcel2Fill className="text-green-800"/>
        </button>
        </div>
      </div>
      <div className="flex justify-center mt-6" data-aos="zoom-in">
        <CreateIngredientTable
          ingredients={currentIngredients}
          handleDeleteIngredient={handleDeleteIngredient}
        />
      </div>
      <div>
        <Pagination
          activePage={currentPage}
          onPageChange={handlePageChange}
          itemsPerPage={itemsPerPage}
          totalItems={ingredients.length}
        />
      </div>
    </div>
  );
};

export default IngredientsTable;
