import React from "react";

import Footer from "../../components/Footer/Footer";
import OneMenuFilter from "../../components/OneMenuFilter/OneMenuFilter";

const FilterMenu = () => {
  const handleGoBack = () => {
    window.history.back();
  };
  return (
    <div className="items-center justify-center">
      <div className="bg-light-pink h-16">
        <div className="flex container" onClick={handleGoBack}>
          <img
            src="images/goback-icon.svg"
            alt="Go back"
            className="mr-2.5 w-7 h-7 cursor-pointer"
          />
          <p className="text-xl font-medium cursor-pointer ">Go back</p>
        </div>
      </div>
      <OneMenuFilter />
    </div>
  );
};

export default FilterMenu;
