import ReservationsForMonth from "../../components/ReservationsForMonth/ReservationsForMonth";

const MonthlyReservation = () => {
  return (
    <div className="home-contianer mb-10">
      <ReservationsForMonth />
    </div>
  );
};

export default MonthlyReservation;
