import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "../../api/axiosInstance";

export const getSemiIngredients = createAsyncThunk(
  "api/getSemiIngredients",
  async () => {
    try {
      const response = await axiosInstace.get(
        "/subIngredient/getAllSubIngredients"
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const createSemiIngredients = createAsyncThunk(
  "api/createSemiIngredients",
  async (createSemiIngredients) => {
    try {
      const response = await axiosInstace.post(
        "/subIngredient/createSubIngredient",
        createSemiIngredients,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const updateSemiIngredients = createAsyncThunk(
  "api/updateSemiIngredients",
  async ({ id, updatedData }) => {
    try {
      const response = await axiosInstace.put(
        `/subIngredient/editSubIngredient/${id}`,
        updatedData
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const deleteSemiIngredient = createAsyncThunk(
  "api/deleteSemiIngredient",
  async (itemId, index) => {
    try {
      await axiosInstace.delete(`/subIngredient/deleteSubIngredient/${itemId}`);
      return itemId;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const deleteSubIngredientPhoto = createAsyncThunk(
  "api/deleteSubIngredientPhoto",
  async ({ itemId, index }, thunkAPI) => {
    try {
      await axiosInstace.delete(
        `/subIngredient/deleteSubIngredientVideo/${itemId}/${index}`
      );
      return { itemId, index };
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

const apiSemiIngredientSlice = createSlice({
  name: "apiSemiIngredients",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSemiIngredients.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSemiIngredients.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getSemiIngredients.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createSemiIngredients.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSemiIngredients.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(createSemiIngredients.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateSemiIngredients.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSemiIngredients.fulfilled, (state, action) => {
        const updatedData = action.payload;
        state.loading = false;
      })
      .addCase(updateSemiIngredients.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteSemiIngredient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSemiIngredient.fulfilled, (state, action) => {
        const deletedItemId = action.payload;
        if (Array.isArray(state.data)) {
          state.data = state.data.filter((item) => item.id !== deletedItemId);
        }
        state.loading = false;
      })
      .addCase(deleteSemiIngredient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteSubIngredientPhoto.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSubIngredientPhoto.fulfilled, (state, action) => {
        const deletedItemId = action.payload;
        if (Array.isArray(state.data)) {
          state.data = state.data.filter((item) => item.id !== deletedItemId);
        }
        state.loading = false;
      })
      .addCase(deleteSubIngredientPhoto.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default apiSemiIngredientSlice.reducer;
