import React, { useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import MediaModal from "../MediaModal/MediaModal";
import { useParams } from "react-router-dom";

const CustomCarousel = ({ dataSubIngredients }) => {
  const baseUrl = "https://backrecrez.bbros.al";
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState({});
  const { id } = useParams();

  const openModal = (type, url) => {
    setSelectedMedia({ type, url });
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const carouselItems = dataSubIngredients
    .filter((item) => item.id == id)
    .flatMap(
      (item) =>
        item?.videos?.map((video, videoIndex) => ({
          type: video.filename.match(/\.(mp4|mov|avi)$/i) ? "video" : "image",
          url: `${baseUrl}/${video.path}`,
        })) || []
    );
  const renderedItems = carouselItems.map((item, index) => (
    <div
      key={index}
      className="carousel-item"
      onClick={() => openModal(item.type, item.url)}
    >
      <div>
        {item.type === "image" && (
          <img
            style={{ width: "500px", height: "300px" }}
            src={item.url}
            alt={`Slide ${index}`}
          />
        )}
        {item.type === "video" && (
          <video className="w-[500px] h-[300px]">
            <source src={item.url} type="video/mp4" />
          </video>
        )}
      </div>
    </div>
  ));

  return (
    <div className="carousel-sub">
      <div className="carousel-container">
        <AliceCarousel
          mouseTracking
          items={renderedItems}
          responsive={{
            0: { items: 1 },
            600: { items: 1 },
            1024: { items: 1 },
          }}
          autoPlay={true}
          autoWidth={800}
          autoPlayInterval={3000}
          animationDuration={500}
          disableButtonsControls
          touchTracking
          swipeDelta
        />
        <MediaModal
          isOpen={modalOpen}
          onClose={closeModal}
          mediaType={selectedMedia.type}
          mediaUrl={selectedMedia.url}
        />
      </div>
    </div>
  );
};

export default CustomCarousel;
